import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const description = "Services tio offers to make digital collabrations including digital consultation, branding, UX/UI, digital product management."

const CaseIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const pages = data.allWpPage.nodes
/*(!pages.length) {
    return (
      <Layout>
        <Seo title="We provide digital partnership to brands." at />
        <p>No case study pages found.</p>
      </Layout>
    )
  }
*/

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title="Services" description={description} />
      <div className=" relative py-10">
        <div className="container font-light text-2xl text-center mx-auto  lg:px-10 ">
            <h1 className="text-5xl p-10 font-bold text-blue">
            Services
            </h1>
            <p className=" mb-10 px-10 mx-auto  font-light max-w-lg">We support D2C brands using our products in their digital operations with our services.</p>  
            <div className=" text-center max-w-4xl p-20 mx-auto ">
                <h3 className="text-blue font-2xl p-10 ">Step 1: Free Consultation</h3>
                <p className="max-w-lg mx-auto">We dive into your workflow before suggesting any digital solution for your business. This consultation helps to understand each other and outline potential steps.</p>
                <div className="block py-5 mt-20 text-sm">
                    <a href="/digital-consultancy" className="bg-blue p-5 rounded py-2 text-white"  >Book a Demo</a>
                </div>
            </div>
            <div className="mx-auto p-20 text-center ">
                <span className="p-10"><StaticImage className="bounce" width={30} src="../images/arrow.png" alt="Before / After tio" /></span>
                <h3 className="text-blue text-xl py-10 lg:p-10 font-thin">THEN WE'LL SUPPORT YOU</h3>
            </div>
        </div>
    </div>

    <div className="container text-xl text-left mx-auto  lg:px-10 ">
            <ul className="flex flex-wrap">
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                  <h3 className="text-3xl font-bold text-blue py-5">Digital Consultancy</h3>
                  <p className="py-5 max-w-sm font-thin">
                  There is always room to make things more connected and simplified to create better experience for your customers. We seek for how to improve things to realize your objectives with digital solutions and create a <b>road map</b> to put things in action.
                  </p>
                  <p className="py-5 max-w-sm font-thin">
                  This service is an intense workshop. We work closely with your team, if necessary in your place to get the most out of this process. It takes from 4 hours to 4 days depending on your needs. All outcomes of the event including photos, files and other recordings are delivered after the event.
                  </p>
                  <Link to="/digital-consultancy" className="text-blue">→ Get in Touch</Link>
                  <div className="w-full p-10">
                  <StaticImage src="../images/roadmap.png" alt="Digital Roadmap" />
                  </div>
                  
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                  <h3 className="text-3xl font-bold text-blue py-5">Branding</h3>
                  <p className="py-5 max-w-sm font-thin">
                  We all know digital visibility is everything today. A better customer experience starts with the communication of your brand. We have awesome partners we work together to create a better digital branding for your business.
                  </p>
                  <Link to="/digital-consultancy" className="text-blue">→ Get in Touch</Link>
                  <div className="w-full p-10">
                  <StaticImage  src="../images/branding.png" alt="Branding" />
                  </div>
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                  <h3 className="text-3xl font-bold text-blue py-5">UX/UI</h3>
                  <p className="py-5 max-w-sm font-thin">
                  We have an exploratory approach for every new product we are creating to forget about our prior opinions of existing similar products. Sometimes we are detectives to question every single act we observe during our user research. Sometimes we count ourselves as the lawyers of the users to defend their rights and comfort.
                  </p>
                  <Link to="/digital-consultancy" className="text-blue">→ Get in Touch</Link>
                  <div className="w-full p-10">
                  <StaticImage  src="../images/ux.png" alt="UX / UI" />
                  </div>
                </li>
                <li className="w-full lg:w-1/2 p-10 lg:p-20">
                  <h3 className="text-3xl font-bold text-blue py-5">Digital Product Management</h3>
                  <p className="py-5 max-w-sm font-thin">
                  We believe a successful product can be created by realizing effective usage of resources, skills and time. Therefore strategic planning for your digital products and management of production process is an important factor. We help you realize your goals and dreams by developing strategies that match your brands soul and capabilities.
                  </p>
                  <Link to="/digital-consultancy" className="text-blue">→ Get in Touch</Link>
                  <div className="w-full p-10">
                  <StaticImage src="../images/marketing.png" alt="Marketing" />
                  </div>
                </li>
            </ul>
  
        </div>
    
      <div className=" relative py-10">
        <div className="container text-xl text-center mx-auto  lg:px-10 max-w-5xl">
          <h2 className="text-4xl md:text-5xl lg:text-6xl max-w-3xl p-10 mx-auto font-bold text-blue">
            We provide digital partnership to brands.
          </h2>
          <p className="font-light">For projects and people we believe in.</p>
        </div>
      </div>
      <div className="max-w-6xl mx-auto my-52">
        <ol className="lg:flex flex-wrap list-none">
          {pages.map(page => {
            const title = page.title
            const featuredImage = {
              data: page.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: page.featuredImage?.node?.alt || ``,
            }
            return (
              <li className="w-full lg:w-2/4 p-10" key={page.uri}>
                <article
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header className="py-5">
                    <Link to={page.uri} itemProp="url">
                      {/* if we have a featured image for this page let's display it */}
                      {featuredImage?.data && (
                        <GatsbyImage
                          image={featuredImage.data}
                          alt={featuredImage.alt}
                          style={{ marginBottom: 30 }}
                        />
                      )}
                      <span
                        className="block text-black font-thin text-xl text-left"
                        itemProp="headline"
                      >
                        {parse(title)}
                      </span>
                    </Link>
                  </header>
                  <Link
                    className="block text-black font-thin text-xl text-left underline"
                    to={page.uri}
                  >
                    → Read Case Study
                  </Link>
                </article>
              </li>
            )
          })}
        </ol>
      </div>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </Layout>
  )
}

export default CaseIndex

export const pageQuery = graphql`
  query WordPressCaseStudyArchive(
    $offset: Int!
    $postsPerPage: Int!
    $language: String!
  ) {
    allWpPage(
      filter: { language: { slug: { eq: $language } }, categories: {nodes: {elemMatch: {slug: {eq: "case-study"}}}}}
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: TRACED_SVG
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
